.form_login {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #15242c;
}

.box_login {
  margin-top: 50px;
  background: #fefefe;
  border: 1px solid #edeef0;
  border-radius: 12px;
}

.box_login_t {
  @apply w-full desktop:w-[700px];
}

.title {
  margin-top: 32px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 18px;

  color: #181818;
}

.subtitle {
  margin-top: 18px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #181818;
}

.login_section {
  @apply flex flex-col text-left;
}

.text_input {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 120% */
  color: #808080;
  margin-bottom: 8px;
}

.box_input {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px;
  background: #dedede;
  border-radius: 24px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  /* black */
  color: #15242c;
}

.button_submit {
  margin-top: 20px;
  margin-bottom: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  background: #181818;
  border-radius: 24px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  text-align: center;

  /* White */
  color: #ffffff;
}
