.today_card {
  /* Home events cards */
  width: 315px;
  height: 234px;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;

  background-image: url(../../assets/todayCard.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.upcoming_card {
  /* Home events cards */

  width: 184px;
  height: 234px;

  /* Primary/red */
  background: #c71f3e;
  border-radius: 40px;

  background-image: url(../../assets/upcomingCard.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.upcoming_card_event {
  /* Home events cards */

  width: 184px;
  height: 234px;

  /* Primary/red */
  background: black;
  border-radius: 40px;

  background-image: url(../../assets/bg_image_upcoming.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.done_card_event {
  /* Home events cards */

  width: 184px;
  height: 234px;

  /* Primary/red */
  background: black;
  border-radius: 40px;

  background-image: url(../../assets/bg_recentEvent.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.done_card {
  width: 184px;
  height: 234px;

  /* Primary / light blue */
  background: #00a0dd;
  border-radius: 40px;

  background-image: url(../../assets/recentlyCard.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.upcoming_card_date {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.upcoming_card_date_no {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.upcoming_card_date {
  /* H1 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 60px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  /* Primary / white */
  color: #ffffff;
}

.upcoming_card_date_calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Primary / white */
  color: #ffffff;
}

.upcoming_card_name {
  /* Subtitle 1 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  /* Primary / white */
  color: #ffffff;
  margin-top: 10px;
}

.upcoming_card_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.done_card_link{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.complete_text{
  /* Button */
font-family: 'Albert Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
display: flex;
align-items: center;
letter-spacing: -0.01em;

/* States/success */
color: #02E075;
}
