.bottom_navbar {
  overflow: hidden;
  position: fixed;
  bottom: 14px;

  width: 100%;
  height: 64px;
  background: rgba(255, 255, 255, 0.2);
  /* Glass */
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
