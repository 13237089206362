.cta {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cta_text {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Primary / light blue */
  color: #00a0dd;
}
