.progress_bar_div {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 21px;
}

.progress_line {
  width: 100%;
  height: 4px;

  /* Action secondary/20% */
  background: #d1eaf4;
  border-radius: 40px;
}

.progress_bar {
  height: 4px;
  background: #00a0dd;
  border-radius: 40px;
}

.progress_text {
  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #00a0dd;
}
