.div_w_back {
  position: relative;
  width: 100%;
  height: 234px;
  border-radius: 40px;
  margin: auto;
}

.d_main_card {
  /* Home events cards */
  width: 100%;
  height: 234px;
  padding-left: 16px;
  padding-right: 16px;
  
}

.description {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin-top: 16px;
}

.card_logo {
  width: 60px;
  height: 60px;

  /* Primary / white */
  background: #ffffff;
  border-radius: 16px;
}

.card_text {
  /* H3 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  /* or 133% */
  display: flex;
  align-items: center;

  /* Primary / white */
  color: #ffffff;
}

.card_info {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin-top: 20px;
}

.player_info {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 99px;
  height: 24px;
}

.player_info_text {
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.card_button {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 16px;
  gap: 10px;

  width: 170px;
  height: 48px;

  /* Primary / white */
  background: #ffffff;
  border-radius: 40px;
}

.card_frame {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 154px;
  height: 48px;

  /* Button */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;
}

.card_main_button {
  width: 48px;
  height: 48px;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;
  transform: rotate(180deg);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.add_player_btn {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  gap: 10px;
  width: 100%;
  height: 48px;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}
