.mainPage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.infoPlayer {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  margin-top: 30px;

  width: 247px;
  height: 45px;
}

.infoPlayer_name {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}

.infoPlayer_score {
  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Neutral / 70% */
  color: #545d69;
}

.infoPlayer_progress {
  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Neutral / 70% */
  color: #545d69;
  margin-bottom: 20px;
}

.scoring {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.scoring_info {
  display: flex;
  flex-direction: row;
}

.scoring_text {
  display: flex;
  flex-direction: column;
}

.attempt_main {
  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #7b7b7b;
}

.attempt_second {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}

.opt_points {
  /* H3 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  /* identical to box height, or 133% */
  text-align: center;
}

.opt_label {
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: -0.01em;
}

.sub_button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 142px;
  gap: 10px;

  width: 343px;
  height: 48px;
  margin-top: 50px;

  /* Neutral/40% */
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.summary_title {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;
}

.summary_desc {
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;
}

.card_attempt {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-top: 24px;

  width: 343px;
  height: 40px;

  border-radius: 16px;
}

.card_left {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 200px;
  height: 40px;
}

.card_attempt_no {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 5px;
  height: 32px;
  /* Subtitle 1 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Neutral/50% */
  color: #858c94;
}

.card_desc {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  justify-content: center;

  width: 100%;
  height: 40px;
}

.card_desc_title {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}

.card_right {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.score_next {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  gap: 10px;
  margin-top: 50px;

  width: 343px;
  height: 100%;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.score_other_player {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 25px;

  width: 343px;
  height: 100%;

  /* Action primary/inverted */
  background: #ffffff;
  /* Primary / blue */
  border: 1px solid #0041eb;
  border-radius: 40px;
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / blue */
  color: #0041eb;
}

.summarySection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
