.header_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 62px;

  /* Margins in Figma*/
  padding-right: 16px;
}

.left_section {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.main_title {
  /* Subtitle 1 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;

  /* Neutral / black */
  color: #0a141e;
}

.numberResult {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 5px;
  gap: 10px;

  /* Neutral / 20% */
  background: #ebeef2;
  backdrop-filter: blur(13px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;

  width: 24px;
  height: 24px;
}

.textResult {
  /* Body 3 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  /* Neutral / 70% */
  color: #545d69;
}

.button_seeAll {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;

  width: 81px;
  height: 32px;

  /* Action secondary/20% */
  background: #d1eaf4;
  backdrop-filter: blur(13px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
}

.text_seeAll {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / light blue */
  color: #00a0dd;
}


/*!* gives us the illusion of a "centered" slide *!*/
.carousel__slider {
  @apply h-full w-full;
}


