.event_top_nav {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 49px;
}

.subcontent {
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.subcontent_title {
  /* Subtitle 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: -0.01em;
  text-align: left;

  /* Primary/black */
  color: #0a141e;

  margin-top: 24px;
}

.stations {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  justify-content: space-evenly;
  margin-top: 16px;
}

.station {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 104px;
  height: 128px;

  /* Neutral / white */
  background: #ffffff;
  /* Neutral / 20% */
  border: 1px solid #ebeef2;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

.station_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.station_text_h1 {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
  text-align: left;
}

.station_text_h2 {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
  text-align: left;
}

.active_station {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 104px;
  height: 128px;

  /* Action primary/Default */
  background: #0041eb;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
