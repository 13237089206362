/* Search bar */
.search_box {
  width: 100%;
  height: 40px;
  background: #ffffff;
  /* Neutral / 20% */
  border: 1px solid #ebeef2;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 16px;

  padding-left: 11px;
}

.search_text {
  width: 100%;
  margin-left: 13px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral/80% */
  color: #394452;

  opacity: 0.8;
}

/* Season component */
.number {
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;

  /* Neutral / 70% */
  color: #545d69;
}

.number_icon {
  @apply flex flex-row items-center;
}

.season_description {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / 70% */
  color: #545d69;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.season_title {
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Neutral / black */
  color: #0a141e;
  margin-bottom: 20px;
}
