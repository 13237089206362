.quick_section {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
}
.quick_section>* {
  flex: 1 1 1 1 100px;
}

.pill {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: fit-content;
  height: 32px;

  /* Primary / white */
  background: #ffffff;
  /* Neutral / 20% */
  border: 1px solid #ebeef2;
  backdrop-filter: blur(13px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;

  /* Body 3 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}

.pill_active {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: fit-content;
  height: 32px;

  /* Action secondary/20% */
  background: #d1eaf4;
  backdrop-filter: blur(13px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;

  /* Body 3 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / light blue */
  color: #00a0dd;
}
