.top_nav {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 49px;

  top: 52px;
}

.top_title {
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}
