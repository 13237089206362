.final_title {
  padding-top: 30px;
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.final_feedback {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 255px;
  height: 164px;
  margin-top: 100px;
}

.final_desc {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 255px;
  height: 68px;
}

.final_score {
  /* H2 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* States/success */
  color: #02e075;
}

.final_player {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.go_to_other {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  gap: 10px;

  width: 343px;
  height: 48px;

  /* Action primary/inverted */
  background: #ffffff;
  /* Primary / blue */
  border: 1px solid #0041eb;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / blue */
  color: #0041eb;
  margin-bottom: 25px;
}

.go_to_station {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  gap: 10px;

  width: 343px;
  height: 48px;

  /* Primary / blue */
  background: #0041eb;
  /* Action primary/inverted */
  border: 1px solid #ffffff;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
  margin-bottom: 25px;
}

.final_buttons{
    margin-top: 40px;
}
