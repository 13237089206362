.player_form_title {
  /* Subtitle 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
  margin-top: 26px;
}

.player_form_lt {
  margin-top: 24px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.player_form_lt_header {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;
}

.player_form_lt_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 16px;
  gap: 10px;

  width: 100%;
  height: 40px;

  /* Neutral / black */
  border: 1px solid #0a141e;
  border-radius: 40px;

  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: left;
  letter-spacing: -0.01em;

  /* Neutral / 70% */
  color: #545d69;
}

.selectWrapper {
  display: inline-block;
  overflow: hidden;
  background: white;
  /* Neutral / black */
  border: 1px solid #0a141e;
  border-radius: 40px;
  width: 100%;
}

.selectBox {
  width: 100%;
  height: 40px;
  border: 0px;
  outline: none;
  background: white;

  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: left;
  letter-spacing: -0.01em;

  /* Neutral / 70% */
  color: #545d69;
}

.action_buttons {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.addBtn {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 142px;
  gap: 10px;

  width: 100%;
  height: 48px;
  left: 16px;
  top: 653px;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.cancelBtn {
  /* Auto layout */
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 142px;
  gap: 10px;

  width: 100%;
  height: 48px;
  left: 16px;
  top: 716px;

  /* Action primary/inverted */
  background: #ffffff;
  /* Primary / blue */
  border: 1px solid #0041eb;
  border-radius: 40px;

  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: -0.01em;

  /* Primary / blue */
  color: #0041eb;
  margin-bottom: 20px;
}
