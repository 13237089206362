.list_header {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
  margin-bottom: 24px;
}

.list_text {
  /* Subtitle 1 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Neutral / black */
  color: #0a141e;
}

.list_number {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 5px;
  gap: 10px;

  width: 24px;
  height: 24px;

  /* Neutral / 20% */
  background: #ebeef2;
  backdrop-filter: blur(13px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;

  /* Body 3 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / 70% */
  color: #545d69;
}

.card_list {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.card_list_item {
  /* cards / player */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0px;
  gap: 16px;

  width: 100%;
  height: 100%;

  /* Primary / white */
  background: #ffffff;
  /* Neutral / 20% */
  border: 1px solid #ebeef2;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 21px;
}

.section1 {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 16px;
  text-align: left;
  width: 80%;
}

.caption {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 250px;
}

.date {
  /* Overline */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Neutral/50% */
  color: #858c94;
}

.name {
  /* Body 1 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.01em;

  /* Primary/black */
  color: #0a141e;
}
.score {
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */
  letter-spacing: 0.02em;
  text-transform: uppercase;

  /* Neutral/50% */
  color: #858c94;
  margin-top: 5px;
}

.podium{
  /* Subtitle 1 */
font-family: 'Bayon';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */
display: flex;
align-items: center;
text-transform: uppercase;

/* Neutral / 60% */
color: #6D7580;
}
