.main_card {
  /* Home events cards */
  width: 315px;
  height: 234px;

  /* Primary/black */
  background: #0a141e;
  border-radius: 40px;
  padding-left: 24px;
  padding-top: 24px;
}

.main_card::before {
  mix-blend-mode: normal;
  opacity: 0.8;
  filter: blur(2px);
  background: #0a141e;
  opacity: 0.5;
}

.time_label {
  /* Auto layout */
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  background: rgba(255, 255, 255, 0.1);
  /* Glass */
  backdrop-filter: blur(12px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;

  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* Primary / white */
  color: #ffffff;
}

.description {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin-top: 16px;
}

.card_logo {
  width: 60px;
  height: 60px;

  /* Primary / white */
  background: #ffffff;
  border-radius: 16px;
}

.card_text {
  /* H3 */
  font-family: "Bayon";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  /* or 133% */
  display: flex;
  align-items: center;

  /* Primary / white */
  color: #ffffff;
}

.card_info {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 26px;
  margin-top: 20px;
}

.player_info {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 99px;
  height: 24px;
}

.player_info_text {
  /* Body 2 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Primary / white */
  color: #ffffff;
}

.card_button {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 16px;
  gap: 10px;

  width: 170px;
  height: 48px;

  /* Primary / white */
  background: #ffffff;
  border-radius: 40px;
}

.card_frame {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 154px;
  height: 48px;

  /* Button */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;
}

.card_main_button {
  width: 48px;
  height: 48px;

  /* Primary / blue */
  background: #0041eb;
  border-radius: 40px;
  transform: rotate(180deg);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
