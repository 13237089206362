/* Search bar */
.search_box_active {
    width: 100%;
  height: 40px;
  background: #ebeef2;
  /* Neutral / 20% */
  border: 1px solid #ebeef2;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 16px;

  padding-left: 11px;
}

.search_text_active {
  width: 100%;
  margin-left: 13px;
  background: #ebeef2;
  /* Body 3 */
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  /* or 133% */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Neutral / black */
  color: #0a141e;

  opacity: 0.8;
}
